import * as React from "react"

export default class BackArrow extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M26,11.4H6.2l9.1-9.1L13,0L0,13l13,13l2.3-2.3l-9.1-9.1H26V11.4z" />
      </svg>
    )
  }
}
