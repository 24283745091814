import axios from "axios"
import { API_KEY, API_BASE_URL } from "../../constants"
import ApplicationForm from "../../models/applicationForm"

export const buildRequestHeaders = () => {
  const headers: any = {
    "Content-Type": "application/json",
    "X-Api-Key": `${API_KEY}`,
  }

  return headers
}

export const post = async (url: string, _: string, ...args: object[]) =>
  await axios.post(`${API_BASE_URL}${url}`, ...args)

export const get = async (url: string, _: string, ...args: object[]) =>
  await axios.get(`${API_BASE_URL}${url}`, ...args)

export const writeRequest = async (
  url: string,
  method: string,
  data: object,
) => {
  const params = { headers: buildRequestHeaders() }
  const response = await post(url, method, data, params)
  return response.data
}

export const readRequest = async (url: string, method: string) => {
  const params = { headers: buildRequestHeaders() }
  const response = await get(url, method, params)
  return response.data
}

export const submitApplicationForm = async (
  applicationForm: ApplicationForm,
) => {
  const {
    email,
    surname,
    name,
    dateOfBirth,
    identificationType,
    identificationNumber,
    identificationExpiration,
    identificationCountry,
    birthPlace,
    birthCountry,
    countriesOfCitizenship,
    streetAddress,
    zipCode,
    city,
    province,
    country,
    planNumber,
  } = applicationForm

  const payload = {
    formData: {
      email,
      surname,
      name,
      dateOfBirth,
      identificationType,
      identificationNumber,
      identificationExpiration,
      identificationCountry,
      birthPlace,
      birthCountry,
      countriesOfCitizenship,
      streetAddress,
      zipCode,
      city,
      province,
      country,
      planNumber,
    },
  }

  return await writeRequest("/appform", "post", payload)
}
