import * as React from "react"

export default class Previous extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 24 24">
        <path d="M19.5,2.1L17.2,0L4.5,12l12.7,12l2.3-2.1L9.1,12L19.5,2.1z" />
        <path d="M0,0h24v24H0V0z" fill="none" />
      </svg>
    )
  }
}
