import { put, takeLatest } from "redux-saga/effects"
import * as action from "../constants/actionTypes"
import { push } from "connected-react-router"

export function* toggleWalkthroughComplete(isComplete: boolean) {
  yield put(push(isComplete ? "/" : "/intro"))
}

export const toolSaga = [
  takeLatest<any>(
    action.TOGGLE_WALKTHROUGH_COMPLETE,
    toggleWalkthroughComplete,
  ),
]

export default toolSaga
