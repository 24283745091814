import * as React from "react"

export default class Youtube extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M10.4,16.9l6.7-3.9l-6.7-3.9V16.9 M25.4,6.8c0.2,0.6,0.3,1.4,0.4,2.4c0.1,1,0.1,1.9,0.1,2.7L26,13c0,2.8-0.2,4.9-0.6,6.2	c-0.3,1.2-1.1,1.9-2.2,2.2c-0.6,0.2-1.7,0.3-3.4,0.4c-1.7,0.1-3.2,0.1-4.7,0.1L13,22c-5.4,0-8.8-0.2-10.2-0.6	c-1.2-0.3-1.9-1.1-2.2-2.2c-0.2-0.6-0.3-1.4-0.4-2.4c-0.1-1-0.1-1.9-0.1-2.7L0,13c0-2.8,0.2-4.9,0.6-6.2c0.3-1.2,1.1-1.9,2.2-2.2	c0.6-0.2,1.7-0.3,3.4-0.4C8,4.1,9.5,4.1,10.9,4.1L13,4c5.4,0,8.8,0.2,10.2,0.6C24.3,4.9,25.1,5.6,25.4,6.8z" />
      </svg>
    )
  }
}
