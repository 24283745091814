import { put, takeLatest, select, call } from "redux-saga/effects"
import * as api from "../lib/api"
import * as action from "../constants/actionTypes"
import * as ApplicationFormActions from "../actions/applicationForm"

export function* submit() {
  try {
    const state = yield select()

    yield call(() => api.submitApplicationForm(state.applicationForm))
    yield put(ApplicationFormActions.submitSuccess())
  } catch (error) {
    yield put(
      ApplicationFormActions.submitError(
        "Application form could not be submitted",
      ),
    )
  }
}

export const applicationFormSaga = [
  takeLatest<any>(action.APP_FORM_SUBMIT, submit),
]

export default applicationFormSaga
