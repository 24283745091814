import * as React from "react"

export default class CapitalIcon extends React.Component {
  public render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 30 63"
      >
        <path d="M30,30.8c-2.7-1.7-5.9-2.9-9-3.9V12.2c3.2,0,6.8,1.2,9,3.6V6.5c-2.9-1.1-6-1.6-9-1.7V0h-3.9v5C9.3,5.5,1.9,10.8,1.9,19.4	c0,9.2,6.7,12.6,15.2,14.9v16.2c-3.7-0.1-8.1-2.4-10.7-5.4L0,51.2c4.6,4.4,10.7,6.5,17.1,6.7v5.2H21v-5.2c3.3-0.1,6.4-1,9-2.7V30.8z M17,25.8c-3.7-1.1-6.4-2.9-6.5-6.9c0-4.2,3.1-6.3,6.5-6.7V25.8z M21,50.3V35.4c3.8,1.3,6.7,3.1,6.7,7.4S25.1,50.1,21,50.3z" />
      </svg>
    )
  }
}
