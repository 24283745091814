import * as ActionTypes from "../constants/actionTypes"
import { WalkthroughState } from "../models"

const walkthroughReducer = (
  state: WalkthroughState = {} as any,
  action: any,
) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_WALKTHROUGH_COMPLETE:
      return {
        ...state,
        isComplete: action.isComplete as boolean,
      }
    default:
      return state
  }
}

export default walkthroughReducer
