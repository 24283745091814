// Index Funds
export const ADD_INDEX_FUND = "ADD_INDEX_FUND"
export const UPDATE_GRAPH = "UPDATE_GRAPH"
// Tool
export const UPDATE_FUND_FILTER = "UPDATE_FUND_FILTER"
export const SET_DIGITAL_FUND = "SET_DIGITAL_FUND"
export const SET_CAPITAL_FUND = "SET_CAPITAL_FUND"
export const ALLOCATE_FUND_SPLIT = "ALLOCATE_FUND_SPLIT"
export const SET_LUMPSUM = "SET_LUMPSUM"
export const SET_MONTHLY_CONTRIBUTION = "SET_MONTHLY_CONTRIBUTION"
export const UPDATE_DURATION = "UPDATE_DURATION"
export const TOGGLE_WALKTHROUGH_COMPLETE = "TOGGLE_WALKTHROUGH_COMPLETE"
// Application Form
export const ADD_PLANHOLDER = "ADD_PLANHOLDER"
export const UPDATE_PLANHOLDER = "UPDATE_PLANHOLDER"
export const DELETE_PLANHOLDER = "DELETE_PLANHOLDER"
export const ADD_BENEFICIARY = "ADD_BENEFICIARY"
export const UPDATE_BENEFICIARY = "UPDATE_BENEFICIARY"
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY"
export const COMPLETE_INVESTMENT_OPTIONS = "COMPLETE_INVESTMENT_OPTIONS"
export const COMPLETE_BENEFICIARIES = "COMPLETE_BENEFICIARIES"
export const COMPLETE_TERMS = "COMPLETE_TERMS"
export const COMPLETE_PLANHOLDERS = "COMPLETE_PLANHOLDERS"
export const UPDATE_APP_FORM_STEP = "UPDATE_APP_FORM_STEP"
export const APP_FORM_SUBMIT = "APP_FORM_SUBMIT"
export const APP_FORM_SUBMIT_SUCCESS = "APP_FORM_SUBMIT_SUCCESS"
export const APP_FORM_SUBMIT_ERROR = "APP_FORM_SUBMIT_ERROR"
