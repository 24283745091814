import React, { useState, Fragment } from "react"
//import ReactDOM from "react-dom"
import Modal from "react-modal"
import { i18n } from "../../i18n"
import MailchimpSignUp from "./MailchimpSignUp/MailchimpSignUp"

//const url = "https://capital.us20.list-manage.com/subscribe/post?u=5eb5c0d776ed6868c4ec8164d&amp;id=d156f102ea"

Modal.setAppElement("#root")

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "100",
  },
}

const SignalService = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  return (
    <Fragment>
      <button
        onClick={openModal}
        className="button primary-button apply-button"
      >
        {" "}
        {i18n.t("tool.apply.view_button_text")}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <MailchimpSignUp closeModal={closeModal} />
      </Modal>
    </Fragment>
  )
}

export default SignalService
