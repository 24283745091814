import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url =
  "https://capital.us20.list-manage.com/subscribe/post?u=5eb5c0d776ed6868c4ec8164d&amp;id=d156f102ea"

const MailchimpForm = ({ status, message, onValidated, closeModal }) => {
  let email
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      })
    closeModal()
  }

  return (
    <div
      style={{
        borderRadius: 2,
        padding: 10,
        display: "inline-block",
        zIndex: 30,
      }}
    >
      <input
        style={{
          fontSize: "2.2em",
          //          padding: 5,
          boxSizing: "border-box",
          webkitBoxSizing: "border-box",
          mozBoxSizing: "border-box",
          border: "1px solid #C2C2C2",
          boxShadow: "1px 1px 4px #EBEBEB",
          mozBoxShadow: "1px 1px 4px #EBEBEB",
          webkitBoxShadow: "1px 1px 4px #EBEBEB",
          borderRadius: "3px",
          webkitBorderRadius: "3px",
          mozBorderRadius: "3px",
          padding: "7px",
          outline: "none",
        }}
        ref={node => (email = node)}
        type="email"
        placeholder="Email"
      />
      <br />
      <button
        className="button primary-button"
        style={{ width: "100%", marginTop: "1rem" }}
        onClick={submit}
      >
        Submit
      </button>
      {/*
      <br />
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )} */}
    </div>
  )
}

const MailchimpSignUp = props => {
  return (
    <div className="">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            closeModal={props.closeModal}
          />
        )}
      />
    </div>
  )
}

export default MailchimpSignUp
