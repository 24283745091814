import * as React from "react"

export default class Discord extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M22.6,8.2l-9.6,6l-9.6-6V5.8l9.6,6l9.6-6 M22.6,3.4H3.4C2.1,3.4,1,4.5,1,5.8v14.4c0,1.3,1.1,2.4,2.4,2.4h19.2	c1.3,0,2.4-1.1,2.4-2.4V5.8C25,4.5,23.9,3.4,22.6,3.4z" />
      </svg>
    )
  }
}
