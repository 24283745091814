import * as React from "react"

export default class Linkedin extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M26,26h-5.8v-9.8c0-1.5-1.7-2.8-3.2-2.8c-1.5,0-2.5,1.3-2.5,2.8V26H8.7V8.7h5.8v2.9c1-1.5,3.4-2.5,5.1-2.5	C23.1,9,26,12,26,15.5V26 M5.8,26H0V8.7h5.8V26 M2.9,0c1.6,0,2.9,1.3,2.9,2.9S4.5,5.8,2.9,5.8S0,4.5,0,2.9S1.3,0,2.9,0z" />
      </svg>
    )
  }
}
