import { GraphData, Investment, FundTypes, ToolState } from "../../models"
import yearValue from "./yearValue"
import arrayOfYears from "../dates/arrayOfYears"

export const buildYearMapper = (
  funds: Investment,
  lumpSum: number,
  monthlyContribution: number,
) => (years: number) => yearValue(funds, years, lumpSum, monthlyContribution)

const plotGraph = (
  funds: Investment,
  years: number,
  lumpSum: number,
  monthlyContribution: number,
): GraphData =>
  arrayOfYears(years, 0).map(
    buildYearMapper(funds, lumpSum, monthlyContribution),
  )

export const filteredFunds = (
  allocation: number,
  capitalFund: FundTypes | null,
  digitalFund: FundTypes | null,
) => {
  if (capitalFund && digitalFund) {
    return [
      { allocation, name: capitalFund },
      { name: digitalFund, allocation: 100 - allocation },
    ]
  }

  if (digitalFund) {
    return [{ name: digitalFund, allocation: 100 - allocation }]
  }

  if (capitalFund) {
    return [{ allocation, name: capitalFund }]
  }

  return []
}

// We need to add 1 to duration so that we get a starting point BEFORE first year
// i.e. the point at which investment starts will yield no returns until end of year
const calculatePeriods = (state: ToolState): ToolState => ({
  ...state,
  graphData: plotGraph(
    filteredFunds(state.allocation, state.capitalFund, state.digitalFund),
    state.duration + 1,
    state.lumpSum,
    state.monthlyContribution,
  ),
})

const calculateResults = (state: ToolState): ToolState => {
  const outCome = state.graphData[state.graphData.length - 1]

  return {
    ...state,
    highCaseOutcome: outCome.high,
    lowCaseOutcome: outCome.low,
    mediumCaseOutcome: outCome.medium,
  }
}

const calculateGraph = (state: ToolState): ToolState =>
  calculateResults(calculatePeriods(state))

export default calculateGraph
