import * as i18n from "i18next"
import * as LanguageDetector from "i18next-browser-languagedetector"

import en from "./locales/en/translations"

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: en,
    },
  },
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
})

export { i18n }
