import * as React from "react"
import {
  DigitalUnconstrainedIcon,
  VanguardIcon,
  Sp500Icon,
  MsciIcon,
  FtseIcon,
} from "../../../assets/icons"
import { FundTypes } from "../../../models"
import { i18n } from "../../../i18n"

interface BenchMarkIndexesProps {
  vanguardSelected: any
  setVanguardSelected: any
  msciSelected: any
  setMsciSelected: any
  sp500Selected: any
  setSp500Selected: any
  ftseSelected: any
  setFtseSelected: any

  showTooltips?: boolean
}

export default class BenchMarkIndexes extends React.Component<
  BenchMarkIndexesProps
> {
  constructor(props: BenchMarkIndexesProps) {
    super(props)
  }

  public render() {
    return (
      <div className="inv-choices-buttons-wrap">
        <div className="benchmark-index-buttons-wrap">
          <div
            onClick={this.handleVanguardSelect}
            className={`${
              FundTypes.DigitalConservative
            } indices-choices-button vanguard-choices-button ${this.props
              .vanguardSelected && "selected"}`}
          >
            <div className="bg-icon">
              <VanguardIcon />
            </div>
            <div className="inv-choices-button-info vanguard-index-info">
              <DigitalUnconstrainedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.vanguard_vti")}</h6>
              {/* <p>{i18n.t("tool.benchmark_indexes.conservative")}</p> */}
            </div>
          </div>
          <div
            onClick={this.handleMsciSelect}
            className={`${
              FundTypes.DigitalConservative
            } indices-choices-button msci-choices-button ${this.props
              .msciSelected && "selected"}`}
          >
            <div className="bg-icon">
              <MsciIcon />
            </div>
            <div className="inv-choices-button-info msci-index-info">
              <DigitalUnconstrainedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.msci_world")}</h6>
              {/* <p>{i18n.t("tool.inv_choices.balanced")}</p> */}
            </div>
          </div>
          <div
            onClick={this.handleSp500Select}
            className={`${
              FundTypes.DigitalConservative
            } indices-choices-button sp500-choices-button ${this.props
              .sp500Selected && "selected"}`}
          >
            <div className="bg-icon">
              <Sp500Icon />
            </div>
            <div className="inv-choices-button-info sp500-index-info">
              <DigitalUnconstrainedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.sp500")}</h6>
              {/* <p>{i18n.t("tool.benchmark_indexes.growth")}</p> */}
            </div>
          </div>

          <div
            onClick={this.handleFtseSelect}
            className={`${
              FundTypes.DigitalConservative
            } indices-choices-button ftse-choices-button ${this.props
              .ftseSelected && "selected"}`}
          >
            <div className="bg-icon">
              <FtseIcon />
            </div>
            <div className="inv-choices-button-info ftse-index-info">
              <DigitalUnconstrainedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.ftse_all")}</h6>
              {/* <p>{i18n.t("tool.benchmark_indexes.cautious")}</p> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleVanguardSelect = () => {
    this.props.vanguardSelected
      ? this.props.setVanguardSelected(false)
      : this.props.setVanguardSelected(true)
  }

  private handleMsciSelect = () => {
    this.props.msciSelected
      ? this.props.setMsciSelected(false)
      : this.props.setMsciSelected(true)
  }

  private handleSp500Select = () => {
    this.props.sp500Selected
      ? this.props.setSp500Selected(false)
      : this.props.setSp500Selected(true)
  }

  private handleFtseSelect = () => {
    this.props.ftseSelected
      ? this.props.setFtseSelected(false)
      : this.props.setFtseSelected(true)
  }
}
