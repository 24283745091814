import * as React from "react"

export default class Sp500 extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 195.000000 103.000000">
        <g transform="translate(35.000000,103.000000) scale(0.08,-0.08)">
          <path
            d="M255 848 c-11 -50 -52 -374 -48 -380 2 -5 27 -8 55 -8 42 0 56 5 85
30 28 25 42 30 84 30 42 0 55 -5 78 -28 34 -33 46 -88 31 -141 -14 -54 -51
-81 -109 -81 -54 0 -84 22 -107 76 -14 34 -15 34 -80 34 l-66 0 7 -32 c3 -18
18 -58 33 -88 23 -45 36 -59 81 -82 45 -24 65 -28 130 -28 88 0 139 20 188 71
45 48 64 100 64 178 1 108 -39 179 -126 224 -31 16 -58 20 -119 20 l-78 0 6
36 c3 20 6 42 6 49 0 9 35 12 140 12 l140 0 0 65 0 65 -195 0 c-194 0 -195 0
-200 -22z"
          />
          <path
            d="M910 859 c-81 -33 -130 -112 -150 -244 -22 -146 2 -304 60 -383 46
-63 96 -85 191 -80 69 3 81 6 116 34 98 79 139 273 99 465 -24 114 -64 173
-138 204 -41 17 -140 19 -178 4z m143 -139 c36 -42 50 -124 45 -250 -4 -89 -8
-113 -29 -150 -45 -82 -113 -78 -151 7 -45 103 -31 341 24 400 32 34 80 31
111 -7z"
          />
          <path
            d="M1470 859 c-81 -33 -130 -112 -150 -244 -22 -146 2 -304 60 -383 46
-63 96 -85 191 -80 69 3 81 6 116 34 98 79 139 273 99 465 -24 114 -64 173
-138 204 -41 17 -140 19 -178 4z m143 -139 c36 -42 50 -124 45 -250 -4 -89 -8
-113 -29 -150 -45 -82 -113 -78 -151 7 -45 103 -31 341 24 400 32 34 80 31
111 -7z"
          />
        </g>
      </svg>
    )
  }
}
