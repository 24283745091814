import { InterestMap, InterestRates, FundTypes } from "../../models"

const fundInterestRates: InterestMap = {
  CapitalUnconstrained: {
    medium: 0.0804,
    high: 0.1138,
    low: 0.0342,
  },
  CapitalBalanced: {
    medium: 0.0934,
    high: 0.109,
    low: 0.0713,
  },
  CapitalConservative: {
    medium: 0.0758,
    high: 0.0856,
    low: 0.0628,
  },
  DigitalUnconstrained: {
    medium: 0.1879,
    high: 0.2564,
    low: 0.1548,
  },
  DigitalBalanced: {
    medium: 0.2511,
    high: 0.3056,
    low: 0.1669,
  },
  DigitalConservative: {
    medium: 0.2538,
    high: 0.307,
    low: 0.1164,
  },
}

export default (fund: FundTypes): InterestRates => fundInterestRates[fund]
