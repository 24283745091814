import * as React from "react"

export default class MorningStar extends React.Component {
  public render() {
    return (
      <svg
        version="1.0"
        viewBox="0 0 344.000000 344.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)"
          fill="#F5F4F2"
          stroke="none"
        >
          <path
            d="M1530 2890 c-626 -86 -1154 -521 -1330 -1095 -66 -213 -74 -269 -74
-515 0 -195 3 -238 22 -325 48 -221 117 -392 228 -562 l54 -83 170 0 c188 0
195 3 139 56 -17 16 -54 61 -83 99 -499 665 -295 1599 438 2004 114 63 303
126 437 147 126 19 366 14 479 -11 262 -58 489 -182 680 -374 193 -194 311
-411 371 -682 31 -139 33 -388 5 -524 -51 -243 -164 -472 -318 -642 -26 -29
-48 -58 -48 -63 0 -6 64 -10 169 -10 l170 0 65 98 c187 281 280 657 248 1005
-20 220 -63 377 -152 565 -190 399 -519 691 -945 839 -148 51 -287 74 -480 78
-99 2 -209 0 -245 -5z"
          />
        </g>
      </svg>
    )
  }
}
