import * as React from "react"
// import { Link } from "react-router-dom"
// import { i18n } from "../../i18n"
import SignalService from "../SignalService/SignalService.js"

export default () => (
  <div className="footer">
    <SignalService />
    {/* <Link
      className="button primary-button apply-button"
      to={{ pathname: "apply" }}
    >
      {i18n.t("tool.apply.view_button_text")}
    </Link> */}
  </div>
)
