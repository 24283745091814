import * as React from "react"

export default class CapitalConservativeIcon extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 19 13">
        <path d="M0,2.4v2.4v1.2v1.2v1.2v1.2v1.2V13h5.4v-2.4V9.5V8.3V7.1V5.9V4.7V2.4H0z M4.4,12H1v-1.4h3.4V12z M4.4,9.5H1V8.3h3.4V9.5z M4.4,7.1H1V5.9h3.4V7.1z M1,4.7V3.4h3.4v1.4H1z M13.6,2.4v2.4v1.2v1.2v1.2v1.2v1.2V13H19v-2.4V9.5V8.3V7.1V5.9V4.7V2.4H13.6z M18,12h-3.4v-1.4H18V12z M18,9.5h-3.4V8.3H18V9.5z M18,7.1h-3.4V5.9H18V7.1z M14.6,4.7V3.4H18v1.4H14.6z M6.8,4.7v2.4v1.2v1.2v1.2	V13h5.4v-2.4V9.5V8.3V7.1V4.7H6.8z M11.2,12H7.8v-1.4h3.4V12z M11.2,9.5H7.8V8.3h3.4V9.5z M7.8,7.1V5.7h3.4v1.4H7.8z" />
      </svg>
    )
  }
}
