import * as React from "react"

export default class Twitter extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M26,5c-1,0.4-2,0.7-3.1,0.9c1.1-0.7,1.9-1.7,2.3-2.9c-1,0.6-2.2,1-3.4,1.3c-1-1.1-2.4-1.7-3.9-1.7c-2.9,0-5.3,2.4-5.3,5.3	c0,0.4,0,0.8,0.1,1.2c-4.4-0.2-8.4-2.3-11-5.5C1.4,4.3,1.1,5.2,1.1,6.1C1.1,8,2,9.6,3.5,10.5c-0.9,0-1.7-0.2-2.4-0.6c0,0,0,0,0,0	c0,2.6,1.8,4.7,4.3,5.2c-0.4,0.1-0.9,0.2-1.4,0.2c-0.3,0-0.7,0-1-0.1c0.7,2.1,2.6,3.6,5,3.7c-1.8,1.4-4.1,2.3-6.6,2.3	c-0.4,0-0.8,0-1.3-0.1c2.4,1.5,5.2,2.4,8.2,2.4c9.8,0,15.2-8.1,15.2-15.1c0-0.2,0-0.5,0-0.7C24.4,7,25.3,6,26,5z" />
      </svg>
    )
  }
}
