import * as React from "react"
import { Link } from "react-router-dom"

export default () => (
  <div>
    ERROR 404: This page cannot be found. Please ensure the link you have
    entered is correct. Naviagte back to app.lima.capital to start again.
    <br />
    <br />
    <Link to="/">Return to Home Page</Link>
  </div>
)
