import * as React from "react"
import { History } from "history"
import { withNamespaces, WithNamespaces } from "react-i18next"
import { ConnectedRouter } from "connected-react-router"
import { Route, Switch } from "react-router"
import { Helmet } from "react-helmet"
import Intro from "./components/Intro/Intro"
// import Walkthrough from "./containers/Walkthrough"
import Tool from "./containers/Tool"
// import ApplicationForm from "./containers/ApplicationForm"
import NotFound from "./components/NotFound/NotFound"
import { i18n } from "./i18n"

interface AppProps extends WithNamespaces {
  history: History
  t: i18n.TranslationFunction
}

const app = ({ history, t }: AppProps) => {
  // <- Include i18n here when ready for other languages!
  /* TODO - Include a function for changing languages and some UI that calls this function with the selected language
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  }
  <button onClick={() => changeLanguage('de')}>de</button>
  */
  return (
    <ConnectedRouter history={history}>
      <div className="app-wrap">
        <Helmet>
          <title>{t("title")}</title>
        </Helmet>
        <Switch>
          <Route path="/intro" component={Intro} />
          {/* <Route path="/walkthrough" component={Walkthrough} /> // On Ice */}
          <Route exact={true} path="/" component={Tool} />
          {/* <Route path="/apply" component={ApplicationForm} /> // On Ice */}
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    </ConnectedRouter>
  )
}

export default withNamespaces()(app)
