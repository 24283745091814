import ApplicationForm from "../models/applicationForm"
import * as ActionTypes from "../constants/actionTypes"

const applicationFormReducer = (
  state: ApplicationForm = {} as any,
  action: any,
) => {
  switch (action.type) {
    case ActionTypes.APP_FORM_SUBMIT:
      return action.applicationForm
    case ActionTypes.APP_FORM_SUBMIT_ERROR:
      return {
        ...state,
        submissionError: action.submissionError as string,
      }
    case ActionTypes.APP_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        submissionError: "",
        isSubmitted: true,
      }
    default:
      return state
  }
}

export default applicationFormReducer
