import React, { useState, useEffect } from "react"
import Graph from "./Graph/Graph"
import Controls from "./Controls/Controls"
import Key from "./Key/Key"
import logo from "../assets/images/Lima-logo200x200.png"
import Footer from "./Footer/Footer"
import SecondaryFooter from "./SecondaryFooter/SecondaryFooter"

import graphDataImported from "../data/data_import.json"

const Tool = (props: any) => {
  const [graphData] = useState(graphDataImported)
  const [filteredData, setFilteredData] = useState(graphData)

  // Lima Funds
  const [limaFundConSelected, setLimaFundConSelected] = useState(false)
  const [limaFundModSelected, setLimaFundModSelected] = useState(false)
  const [limaFundAggSelected, setLimaFundAggSelected] = useState(true)

  // Vanguard Funds
  const [vanguardFundConsSelected, setVanguardFundConsSelected] = useState(
    false,
  )
  const [vanguardFundModSelected, setVanguardFundModSelected] = useState(false)
  const [vanguardFundAggSelected, setVanguardFundAggSelected] = useState(true)

  // EAA Funds
  const [eaaFundConsSelected, seteaaFundConsSelected] = useState(false)
  const [eaaFundModSelected, seteaaFundModSelected] = useState(false)
  const [eaaFundAggSelected, seteaaFundAggSelected] = useState(true)

  // Indicies
  const [vanguardSelected, setVanguardSelected] = useState(false)
  const [msciSelected, setMsciSelected] = useState(false)
  const [sp500Selected, setSp500Selected] = useState(false)
  const [ftseSelected, setFtseSelected] = useState(false)

  useEffect(() => {
    setFilteredData(graphData)
  }, [graphData])

  // Helper function to duplicate an object and not merely create a reference
  const clone = (obj: any) => {
    if (obj === null || typeof obj !== "object") {
      return obj
    }

    const temp = new obj.constructor()
    for (const key in obj) {
      temp[key] = clone(obj[key])
    }

    return temp
  }

  const cumulateData = (durationYears: number = 100) => {
    props.setDuration(durationYears)

    let graphDataTemp = clone(graphDataImported)

    const monthsInYear = 12
    const numberOfMonths = durationYears * monthsInYear

    graphDataTemp = graphDataTemp.slice(
      Math.max(graphDataTemp.length - numberOfMonths, 1),
    )
    const multiplier = 100000

    graphDataTemp[0]["Lima_Unconstrained"] = multiplier
    graphDataTemp[0]["Vanguard_LifeStrategy_Growth"] = multiplier
    graphDataTemp[0]["MSCI_Pacific_ex_Japan"] = multiplier
    graphDataTemp[0]["Etherbridge"] = multiplier
    graphDataTemp[0]["Vanguard_LifeStrategy_Moderate"] = multiplier
    graphDataTemp[0]["MSCI_Emerging_Markets"] = multiplier
    graphDataTemp[0]["Voyager"] = multiplier
    graphDataTemp[0]["Vanguard_LifeStrategy_Conservative"] = multiplier
    graphDataTemp[0]["Bitcoin"] = multiplier
    graphDataTemp[0]["Vanguard_VTI"] = multiplier
    graphDataTemp[0]["MSCI_World_TR_Index"] = multiplier
    graphDataTemp[0]["S&P_500_Index_USD"] = multiplier
    graphDataTemp[0]["FTSE_All_Share_GBP"] = multiplier

    for (let i = 1; i < graphDataTemp.length; i += 1) {
      graphDataTemp[i]["Lima_Unconstrained"] =
        graphDataTemp[i]["Lima_Unconstrained"] * multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Growth"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Growth"] * multiplier
      graphDataTemp[i]["MSCI_Pacific_ex_Japan"] =
        graphDataTemp[i]["MSCI_Pacific_ex_Japan"] * multiplier
      graphDataTemp[i]["Etherbridge"] =
        graphDataTemp[i]["Etherbridge"] * multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Moderate"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Moderate"] * multiplier
      graphDataTemp[i]["MSCI_Emerging_Markets"] =
        graphDataTemp[i]["MSCI_Emerging_Markets"] * multiplier
      graphDataTemp[i]["Voyager"] = graphDataTemp[i]["Voyager"] * multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Conservative"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Conservative"] * multiplier
      graphDataTemp[i]["Bitcoin"] = graphDataTemp[i]["Bitcoin"] * multiplier
      graphDataTemp[i]["Vanguard_VTI"] =
        graphDataTemp[i]["Vanguard_VTI"] * multiplier
      graphDataTemp[i]["MSCI_World_TR_Index"] =
        graphDataTemp[i]["MSCI_World_TR_Index"] * multiplier
      graphDataTemp[i]["S&P_500_Index_USD"] =
        graphDataTemp[i]["S&P_500_Index_USD"] * multiplier
      graphDataTemp[i]["FTSE_All_Share_GBP"] =
        graphDataTemp[i]["FTSE_All_Share_GBP"] * multiplier
    }

    for (let i = 2; i < graphDataTemp.length; i += 1) {
      // VTI
      graphDataTemp[i]["Lima_Unconstrained"] +=
        graphDataTemp[i - 1]["Lima_Unconstrained"]
      graphDataTemp[i]["Vanguard_LifeStrategy_Growth"] +=
        graphDataTemp[i - 1]["Vanguard_LifeStrategy_Growth"]
      graphDataTemp[i]["MSCI_Pacific_ex_Japan"] +=
        graphDataTemp[i - 1]["MSCI_Pacific_ex_Japan"]
      graphDataTemp[i]["Etherbridge"] += graphDataTemp[i - 1]["Etherbridge"]
      graphDataTemp[i]["Vanguard_LifeStrategy_Moderate"] +=
        graphDataTemp[i - 1]["Vanguard_LifeStrategy_Moderate"]
      graphDataTemp[i]["MSCI_Emerging_Markets"] +=
        graphDataTemp[i - 1]["MSCI_Emerging_Markets"]
      graphDataTemp[i]["Voyager"] += graphDataTemp[i - 1]["Voyager"]
      graphDataTemp[i]["Vanguard_LifeStrategy_Conservative"] +=
        graphDataTemp[i - 1]["Vanguard_LifeStrategy_Conservative"]
      // // EAA
      graphDataTemp[i]["Bitcoin"] += graphDataTemp[i - 1]["Bitcoin"]
      graphDataTemp[i]["Vanguard_VTI"] += graphDataTemp[i - 1]["Vanguard_VTI"]
      graphDataTemp[i]["MSCI_World_TR_Index"] +=
        graphDataTemp[i - 1]["MSCI_World_TR_Index"]
      graphDataTemp[i]["S&P_500_Index_USD"] +=
        graphDataTemp[i - 1]["S&P_500_Index_USD"]
      graphDataTemp[i]["FTSE_All_Share_GBP"] +=
        graphDataTemp[i - 1]["FTSE_All_Share_GBP"]
    }

    for (let i = 1; i < graphDataTemp.length; i += 1) {
      graphDataTemp[i]["Lima_Unconstrained"] =
        graphDataTemp[i]["Lima_Unconstrained"] + multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Growth"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Growth"] + multiplier
      graphDataTemp[i]["MSCI_Pacific_ex_Japan"] =
        graphDataTemp[i]["MSCI_Pacific_ex_Japan"] + multiplier
      graphDataTemp[i]["Etherbridge"] =
        graphDataTemp[i]["Etherbridge"] + multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Moderate"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Moderate"] + multiplier
      graphDataTemp[i]["MSCI_Emerging_Markets"] =
        graphDataTemp[i]["MSCI_Emerging_Markets"] + multiplier
      graphDataTemp[i]["Voyager"] = graphDataTemp[i]["Voyager"] + multiplier
      graphDataTemp[i]["Vanguard_LifeStrategy_Conservative"] =
        graphDataTemp[i]["Vanguard_LifeStrategy_Conservative"] + multiplier
      graphDataTemp[i]["Bitcoin"] = graphDataTemp[i]["Bitcoin"] + multiplier
      graphDataTemp[i]["Vanguard_VTI"] =
        graphDataTemp[i]["Vanguard_VTI"] + multiplier
      graphDataTemp[i]["MSCI_World_TR_Index"] =
        graphDataTemp[i]["MSCI_World_TR_Index"] + multiplier
      graphDataTemp[i]["S&P_500_Index_USD"] =
        graphDataTemp[i]["S&P_500_Index_USD"] + multiplier
      graphDataTemp[i]["FTSE_All_Share_GBP"] =
        graphDataTemp[i]["FTSE_All_Share_GBP"] + multiplier
    }

    setFilteredData(graphDataTemp)
  }

  useEffect(() => {
    cumulateData()
  }, [graphData])

  return (
    <div className="tool-wrap">
      <div className="header">
        <a href="https://lima.capital">
          <img src={logo} className="header-logo" />
        </a>
      </div>
      <div className="graph-wrap">
        <Key
          graphKeys={filteredData[filteredData.length - 1]} // Last element
          limaFundModSelected={limaFundModSelected}
          limaFundAggSelected={limaFundAggSelected}
          limaFundConSelected={limaFundConSelected}
          eaaFundConsSelected={eaaFundConsSelected}
          eaaFundModSelected={eaaFundModSelected}
          eaaFundAggSelected={eaaFundAggSelected}
          vanguardFundConsSelected={vanguardFundConsSelected}
          vanguardFundModSelected={vanguardFundModSelected}
          vanguardFundAggSelected={vanguardFundAggSelected}
          vanguardSelected={vanguardSelected}
          msciSelected={msciSelected}
          sp500Selected={sp500Selected}
          ftseSelected={ftseSelected}
        />
        <Graph
          data={filteredData}
          duration={props.duration}
          limaFundConSelected={limaFundConSelected}
          limaFundModSelected={limaFundModSelected}
          limaFundAggSelected={limaFundAggSelected}
          eaaFundConsSelected={eaaFundConsSelected}
          eaaFundModSelected={eaaFundModSelected}
          eaaFundAggSelected={eaaFundAggSelected}
          vanguardFundConsSelected={vanguardFundConsSelected}
          vanguardFundModSelected={vanguardFundModSelected}
          vanguardFundAggSelected={vanguardFundAggSelected}
          vanguardSelected={vanguardSelected}
          msciSelected={msciSelected}
          sp500Selected={sp500Selected}
          ftseSelected={ftseSelected}
        />
        <p className="investment-context-disclaimer">
          Source: Lima Capital LLC, Bloomberg L.P and Vanguard and DeFi Pulse.
          <br />
          Basis: Returns for the Funds are shown ‘net’ of management fees, and
          all simulated performance is ‘gross’ and based on an initial
          investment of US$100 000. Some of the information herein may contain
          projections or other forward-looking statements regarding future
          events or future financial performance of countries, assets, markets
          or companies.
        </p>

        <p className="investment-context-disclaimer">
          The Voyager Fund performance is derived from a ‘back-test’; which
          maximises yield for a basket of attainable yield opportunities that
          conform to the risk framework as set out by the fund managers. The
          returns are calculated on a portfolio that holds a basket of crypto
          assets and cash or cash equivalents, which is rebalanced dynamically
          according to the availability of new opportunities. Any past
          performance information presented is not indicative of future
          performance. Performance can go down as well as up.
        </p>
      </div>
      <Controls
        setLimaFundModSelected={setLimaFundModSelected}
        limaFundModSelected={limaFundModSelected}
        setLimaFundAggSelected={setLimaFundAggSelected}
        limaFundAggSelected={limaFundAggSelected}
        limaFundConSelected={limaFundConSelected}
        setLimaFundConSelected={setLimaFundConSelected}
        eaaFundConsSelected={eaaFundConsSelected}
        eaaFundModSelected={eaaFundModSelected}
        eaaFundAggSelected={eaaFundAggSelected}
        seteaaFundConsSelected={seteaaFundConsSelected}
        seteaaFundModSelected={seteaaFundModSelected}
        seteaaFundAggSelected={seteaaFundAggSelected}
        vanguardFundConsSelected={vanguardFundConsSelected}
        vanguardFundModSelected={vanguardFundModSelected}
        vanguardFundAggSelected={vanguardFundAggSelected}
        setVanguardFundConsSelected={setVanguardFundConsSelected}
        setVanguardFundModSelected={setVanguardFundModSelected}
        setVanguardFundAggSelected={setVanguardFundAggSelected}
        vanguardSelected={vanguardSelected}
        setVanguardSelected={setVanguardSelected}
        msciSelected={msciSelected}
        setMsciSelected={setMsciSelected}
        sp500Selected={sp500Selected}
        setSp500Selected={setSp500Selected}
        ftseSelected={ftseSelected}
        setFtseSelected={setFtseSelected}
        cumulateData={cumulateData}
        graphData={graphData}
        duration={props.duration}
        setDuration={props.setDuration}
      />
      <Footer />
      <SecondaryFooter />
    </div>
  )
}

export default Tool
