import Tool from "../components/Tool"
import { connect } from "react-redux"
import * as actions from "../actions"
import { Dispatch } from "redux"
import { GraphFilters, FundTypes, AppState } from "../models"

export const mapStateToProps = (state: AppState) => state.tool

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFundFilter: (filter: GraphFilters) =>
    dispatch(actions.updateFundFilter(filter)),
  setDigitalFund: (fund: FundTypes) => dispatch(actions.setDigitalFund(fund)),
  setCapitalFund: (fund: FundTypes) => dispatch(actions.setCapitalFund(fund)),
  setAllocation: (capitalPercentage: number) =>
    dispatch(actions.allocateFundSplit(capitalPercentage)),
  setLumpSum: (amount: number) => dispatch(actions.setLumpSum(amount)),
  setMonthlyContribution: (amount: number) =>
    dispatch(actions.setMonthlyContribution(amount)),
  setDuration: (years: number) => dispatch(actions.setDuration(years)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tool)
