import * as React from "react"

interface TooltipProps {
  children: React.ReactNode
  positionX?: string
  positionY?: string
  open: boolean
}

class Tooltip extends React.Component<TooltipProps> {
  private x: string
  private y: string

  constructor(props: TooltipProps) {
    super(props)
    this.x = this.props.positionX || "mid"
    this.y = this.props.positionY || "top"
  }

  public render() {
    return (
      <div>
        {this.props.open && (
          <div className={`tooltip ${this.x} ${this.y}`}>
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

export default Tooltip
