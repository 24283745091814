import * as types from "../constants/actionTypes"
import ApplicationFormState from "../models/applicationForm"

const submit = (applicationForm: ApplicationFormState) => ({
  applicationForm,
  type: types.APP_FORM_SUBMIT,
})

const submitSuccess = () => ({
  type: types.APP_FORM_SUBMIT_SUCCESS,
})

const submitError = (submissionError: string) => ({
  submissionError,
  type: types.APP_FORM_SUBMIT_ERROR,
})

export { submit, submitSuccess, submitError }
