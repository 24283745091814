import { combineReducers } from "redux"
import { History } from "history"
import { connectRouter } from "connected-react-router"
import toolReducer from "./tool"
import walkthroughReducer from "./walkthrough"
import applicationFormReducer from "./applicationForm"

const rootReducer = (history: History) =>
  combineReducers({
    tool: toolReducer,
    walkthrough: walkthroughReducer,
    applicationForm: applicationFormReducer,
    router: connectRouter(history),
  })

export default rootReducer
