import * as React from "react"
import * as ReactDOM from "react-dom"
import { routerMiddleware, push } from "connected-react-router"
import { I18nextProvider } from "react-i18next"
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import { createBrowserHistory } from "history"
import gtmMiddleware from "./gtmMiddleware"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import App from "./App"
import { i18n } from "./i18n"
import "./index.css"
import { unregister } from "./registerServiceWorker"
import { saveAppState, loadAppState } from "./lib/storage"

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const loadedState = loadAppState()

const store = createStore(
  rootReducer(history),
  loadedState,
  composeEnhancer(
    applyMiddleware(routerMiddleware(history), gtmMiddleware, sagaMiddleware),
  ),
)

store.subscribe(() => {
  saveAppState(store.getState())
})

sagaMiddleware.run(rootSaga)

if (!store.getState().walkthrough.isComplete) {
  store.dispatch(push("/intro"))
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </I18nextProvider>,
  document.getElementById("root") as HTMLElement,
)

// Disabling the service worker cache for now
unregister()
