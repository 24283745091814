const chunkIntoThreesReducer = (acc: string[], current: string) => {
  if (!acc.length) {
    return [current]
  }

  if (acc[0].length < 3) {
    return [`${acc[0]}${current}`, ...acc.slice(1)]
  }

  return [current, ...acc]
}

const commariser = (x: string) =>
  x
    .split("")
    .reverse()
    .reduce(chunkIntoThreesReducer, [])
    .reverse()
    .join(",")
    .split("")
    .reverse()
    .join("")

export default commariser
