import * as React from "react"

export default class Medium extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M3.1,6.8c0-0.3-0.1-0.6-0.3-0.9l-2.4-3V2.5h7.5l5.8,13l5.1-13H26v0.4l-2.1,2c-0.2,0.1-0.3,0.4-0.2,0.6v14.9	c0,0.2,0.1,0.5,0.2,0.6l2,2v0.4H15.8v-0.4l2.1-2.1c0.2-0.2,0.2-0.3,0.2-0.6v-12l-5.8,15.1h-0.8L4.6,8.4v10.1	c-0.1,0.4,0.1,0.8,0.4,1.1L7.7,23v0.4H0V23l2.7-3.4c0.3-0.3,0.4-0.7,0.4-1.1V6.8z" />
      </svg>
    )
  }
}
