import { Investment } from "../../models"
import futureValue from "./futureValue"
import fundInterestRates from "./interestRate"
import currentYear from "../dates/currentYear"
import floatSafeAdd from "../numbers/floatSafeAdd"

const valueCalculator = (
  years: number,
  lumpSum: number,
  monthlyContribution: number,
) => (interestRate: number) =>
  futureValue(interestRate, years, lumpSum, monthlyContribution)

const yearValue = (
  funds: Investment,
  years: number,
  lumpSum: number,
  monthlyContribution: number,
) =>
  funds.reduce(
    (accumulator, current) => {
      const value = valueCalculator(
        years,
        lumpSum * (current.allocation / 100),
        monthlyContribution * (current.allocation / 100),
      )
      const rates = fundInterestRates(current.name)

      return {
        ...accumulator,
        medium: floatSafeAdd(accumulator.medium, value(rates.medium)),
        high: floatSafeAdd(accumulator.high, value(rates.high)),
        low: floatSafeAdd(accumulator.low, value(rates.low)),
      }
    },
    {
      medium: 0,
      high: 0,
      low: 0,
      xaxis: `${currentYear + years}`,
    },
  )

export default yearValue
