import { createMiddleware, EventsMap, EventDefinition } from "redux-beacon"
import GoogleTagManager from "@redux-beacon/google-tag-manager"
import * as ActionTypes from "./constants/actionTypes"

/*** Note that page views for this SPA are handled automatically by GTM setup using "History Change" ***/

const updateFundFilterEvent: EventDefinition = (action: any) =>
  calculatorEvent(action.type, action.capitalPercentage)

const updateDurationEvent: EventDefinition = (action: any) =>
  calculatorEvent(action.type, action.years)

const setFundEvent: EventDefinition = (action: any) =>
  calculatorEvent(action.type, action.fund)

const allocateFundSplitEvent: EventDefinition = (action: any) =>
  calculatorEvent(action.type, action.capitalPercentage)

const setAmountEvent: EventDefinition = (action: any) =>
  calculatorEvent(action.type, action.amount)

const calculatorEvent = (actionType: any, value: any) => {
  return {
    value,
    event: "calculate",
    category: "Tool Calculate",
    label: actionType,
  }
}

const eventsMap: EventsMap = {
  // Tool/Calculator
  [ActionTypes.UPDATE_FUND_FILTER]: updateFundFilterEvent,
  [ActionTypes.UPDATE_DURATION]: updateDurationEvent,
  [ActionTypes.SET_DIGITAL_FUND]: setFundEvent,
  [ActionTypes.SET_CAPITAL_FUND]: setFundEvent,
  [ActionTypes.ALLOCATE_FUND_SPLIT]: allocateFundSplitEvent,
  [ActionTypes.SET_LUMPSUM]: setAmountEvent,
  [ActionTypes.SET_MONTHLY_CONTRIBUTION]: setAmountEvent,
}

const gtm = GoogleTagManager()
const gtmMiddleware = createMiddleware(eventsMap, gtm)

export default gtmMiddleware
