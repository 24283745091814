export default {
  title: "Lima Capital",
  tool: {
    keys: {
      Lima_Unconstrained: "Lima Unconstrained",
      Vanguard_LifeStrategy_Growth: "Vanguard Growth",
      MSCI_Pacific_ex_Japan: "MSCI Pacific",
      Etherbridge: "Etherbridge",
      Vanguard_LifeStrategy_Moderate: "Vanguard Moderate",
      MSCI_Emerging_Markets: "MSCI Emerging Markets",
      Voyager: "Voyager",
      Vanguard_LifeStrategy_Conservative: "Vanguard Conservative",
      Bitcoin: "Bitcoin",
      Vanguard_VTI: "Vanguard VTI",
      MSCI_World_TR_Index: "MSCI World",
      SP_500_Index_USD: "S&P 500",
      FTSE_All_Share_GBP: "FTSE All Share",
    },
    inv_choices: {
      heading: "Investment Choices",
      tab_heading: "Choices",
      voyager: "Voyager High Yield",
      etherbridge: "Etherbridge",
      balanced: "Balanced",
      unconstrained: "Unconstrained",
      digital: "Digital",
      capital: "Capital",
      lima: "Lima",
      defi: "DeFi",
      bakari: "Voyager High Yield",
    },
    index: {
      heading: "Lima funds",
    },
    timeframe: {
      one_year: "1Y",
      two_years: "2Y",
      three_years: "3Y",
      five_years: "5Y",
      ten_years: "10Y",
      max_period: "MAX",
    },
    peergroup_indexes: {
      heading: "Peer Groups",
      vanguard_lifestrategy_fund: "Vanguard LifeStrategy",
      bitcoin: "BITCOIN",
      eaa_fund: "EAA Fund",
      conservative: "Bitcoin",
      cautious: "Bitcoin",
      moderate: "Emerging Markets",
      aggressive: "Pacific",
      growth: "Growth",
    },
    benchmark_indexes: {
      heading: "Indices",
      vanguard_lifestrategy_fund: "Vanguard LifeStrategy Fund",
      vanguard_vti: "Vanguard VTI",
      msci_world: "MSCI World TR",
      sp500: "S&P 500",
      ftse_all: "FTSE All Share",
      bitcoin: "BITCOIN",
      eaa_fund: " MSCI",
      conservative: "Conservative",
      cautious: "Bitcoin",
      moderate: "Emerging Markets",
      aggressive: "Pacific",
      growth: "Growth",
    },
    inv_amount: {
      heading: "Investment Amount",
      tab_heading: "Amount",
      lump_sum: "Lump Sum",
      monthly_payment: "Monthly payment",
    },
    inv_timeframe: {
      heading: "Timeframe",
      tab_heading: "Timeframe",
      years: "Years",
      instructions: "Select your timeframe by sliding left or right",
    },
    results: {
      morningstar: "Morningstar",
      eea: "EEA Fund",
      vti: "Lima fund",
      blend: "Blend",
      digital: "Digital",
      capital: "Capital",
      x_axis_label: "Timeframe",
      y_axis_label: "Amount in US $",
      eaa_aggressive_cumulative_performance: "EAA FUND Aggressive",
      eaa_fund_cautious: "EAA FUND Cautious",
      cumulative_performance_vti: "Lima FUND",
    },
    apply: {
      view_button_text: "Subscribe to stay updated",
    },
  },
  next_steps: {
    heading: "Lima next steps",
    back_button_text: "Back to Simulator",
    intro: {
      heading: "Intro",
      body:
        "We're currently working hard on the next stages of development of the tool which would take you through the process of investing.",
    },
    application_form: {
      heading: "Application Form",
      body:
        "You will complete a fully digital step-by-step KYC/AML and application form.",
    },
    signup_form: {
      heading: "Sign Up",
      instructions: "Enter your details to keep up to date.",
      name_label: "Name",
      email_label: "Email",
      name_placeholder: "John Doe",
      email_placeholder: "email@example.com",
      submit_button_text: "Sign Up",
      thanks_heading: "Thanks for signing up",
      thanks_sub_heading: "You will now receive Stackr news and updates.",
      thanks_done_button_text: "Done",
    },
  },
  disclaimer: "Disclaimer",
}
