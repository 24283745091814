import * as React from "react"

export default class Ftse extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 195.000000 103.000000">
        <g transform="translate(26, 23) scale(0.43,0.43)">
          <path
            id="path0"
            d="M48.547 32.137 C 47.795 32.889,47.179 61.966,47.179 96.752 L 47.179 160.000 60.513 160.000 L 73.846 160.000 73.846 132.308 L 73.846 104.615 94.359 104.615 L 114.872 104.615 114.872 94.359 L 114.872 84.103 94.359 84.103 L 73.846 84.103 73.846 67.692 L 73.846 51.282 94.359 51.282 L 114.872 51.282 114.872 41.026 L 114.872 30.769 82.393 30.769 C 64.530 30.769,49.299 31.385,48.547 32.137 M118.974 41.026 L 118.974 51.282 133.333 51.282 L 147.692 51.282 147.692 105.641 L 147.692 160.000 160.000 160.000 L 172.308 160.000 172.308 105.641 L 172.308 51.282 186.667 51.282 L 201.026 51.282 201.026 41.026 L 201.026 30.769 160.000 30.769 L 118.974 30.769 118.974 41.026 M219.534 35.267 C 192.419 49.240,195.949 83.290,226.328 100.804 C 246.066 112.182,250.271 116.261,250.191 123.947 C 250.027 139.841,231.785 145.688,212.630 135.985 C 205.238 132.241,204.747 132.839,204.394 146.004 L 204.103 156.865 214.359 159.569 C 273.431 175.147,302.131 114.925,249.973 84.840 C 214.809 64.558,226.948 39.628,264.835 54.320 C 268.869 55.885,272.279 37.615,268.634 33.964 C 263.903 29.226,229.485 30.140,219.534 35.267 M287.179 95.385 L 287.179 160.000 320.000 160.000 L 352.821 160.000 352.821 149.744 L 352.821 139.487 333.333 139.487 L 313.846 139.487 313.846 122.051 L 313.846 104.615 333.333 104.615 L 352.821 104.615 352.821 94.359 L 352.821 84.103 333.333 84.103 L 313.846 84.103 313.846 67.692 L 313.846 51.282 333.333 51.282 L 352.821 51.282 352.821 41.026 L 352.821 30.769 320.000 30.769 L 287.179 30.769 287.179 95.385 "
          />
        </g>
      </svg>
    )
  }
}
