import * as React from "react"

export default class Next extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 24 24">
        <path d="M4.5,21.9L6.8,24l12.7-12L6.8,0L4.5,2.1L14.9,12L4.5,21.9z" />
        <path d="M0,0h24v24H0V0z" fill="none" />
      </svg>
    )
  }
}
