import { GraphFilters, ToolState } from "../models"
import * as ActionTypes from "../constants/actionTypes"
import calculateGraph from "../lib/graph"

const noCurrentFundofThisType = (action: any, state: ToolState) =>
  (action.type === ActionTypes.SET_DIGITAL_FUND
    ? noCurrentDigitalFund
    : noCurrentCapitalFund)(state)
const noCurrentDigitalFund = (state: ToolState) => !state.digitalFund
const noCurrentCapitalFund = (state: ToolState) => !state.capitalFund
const noFundsWereSelectedBefore = (state: ToolState) =>
  !state.capitalFund && !state.digitalFund
const theOtherFundWillBeTheOnlySelected = (action: any, state: ToolState) =>
  (state.digitalFund === action.fund && state.capitalFund) ||
  (state.capitalFund === action.fund && state.digitalFund)
const noFundsWillBeSelected = (action: any, state: ToolState) =>
  (state.digitalFund === action.fund && !state.capitalFund) ||
  (state.capitalFund === action.fund && !state.digitalFund)

const leaveAllocationAlone = (action: any, state: ToolState) =>
  (action.type === ActionTypes.SET_DIGITAL_FUND
    ? setDigitalFund
    : setCapitalFund)(action, state)

const setDigitalFund = (action: any, state: ToolState) => {
  const fund = state.digitalFund === action.fund ? null : action.fund

  return {
    ...state,
    graphFilter: GraphFilters.Blend,
    digitalFund: fund,
  }
}

const setCapitalFund = (action: any, state: ToolState) => {
  const fund = state.capitalFund === action.fund ? null : action.fund

  return {
    ...state,
    graphFilter: GraphFilters.Blend,
    capitalFund: fund,
  }
}

const allocateEvenSplit = (action: any, state: ToolState) => ({
  ...leaveAllocationAlone(action, state),
  allocation: 50,
})

const allocateAllToTheOtherFund = (action: any, state: ToolState) =>
  (action.type === ActionTypes.SET_DIGITAL_FUND
    ? allocateAllToCapital
    : allocateAllToDigital)(action, state)
const allocateAllToTheThisFund = (action: any, state: ToolState) =>
  (action.type === ActionTypes.SET_DIGITAL_FUND
    ? allocateAllToDigital
    : allocateAllToCapital)(action, state)

const allocateAllToCapital = (action: any, state: ToolState) => ({
  ...leaveAllocationAlone(action, state),
  allocation: 100,
})

const allocateAllToDigital = (action: any, state: ToolState) => ({
  ...leaveAllocationAlone(action, state),
  allocation: 0,
})

const updateFundFilter = (action: any, state: ToolState) => ({
  ...state,
  graphFilter: action.filter,
})

const allocateFundSplit = (action: any, state: ToolState) => ({
  ...state,
  allocation: action.capitalPercentage,
})

const setLumpSum = (action: any, state: ToolState) => ({
  ...state,
  lumpSum: action.amount,
})

const setMonthlyContribution = (action: any, state: ToolState) => ({
  ...state,
  monthlyContribution: action.amount,
})

const updateDuration = (action: any, state: ToolState) => ({
  ...state,
  duration: action.years,
})

const updateGraph = (action: any, state: ToolState) => ({
  ...state,
  fund: action.fund,
})

const allocateFunds = (action: any, state: ToolState) => {
  if (noFundsWereSelectedBefore(state)) {
    return allocateAllToTheThisFund(action, state)
  }

  if (
    noCurrentFundofThisType(action, state) ||
    noFundsWillBeSelected(action, state)
  ) {
    return allocateEvenSplit(action, state)
  }

  if (theOtherFundWillBeTheOnlySelected(action, state)) {
    return allocateAllToTheOtherFund(action, state)
  }

  return leaveAllocationAlone(action, state)
}

const toolReducer = (state: ToolState = {} as any, action: any) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FUND_FILTER:
      return calculateGraph(updateFundFilter(action, state))
    case ActionTypes.SET_CAPITAL_FUND:
    case ActionTypes.SET_DIGITAL_FUND:
      return calculateGraph(allocateFunds(action, state))
    case ActionTypes.ALLOCATE_FUND_SPLIT:
      return calculateGraph(allocateFundSplit(action, state))
    case ActionTypes.SET_LUMPSUM:
      return calculateGraph(setLumpSum(action, state))
    case ActionTypes.SET_MONTHLY_CONTRIBUTION:
      return calculateGraph(setMonthlyContribution(action, state))
    case ActionTypes.UPDATE_DURATION:
      return calculateGraph(updateDuration(action, state))
    case ActionTypes.UPDATE_GRAPH:
      return calculateGraph(updateGraph(action, state))
    default:
      return state
  }
}

export default toolReducer
