import * as React from "react"

export default class Discord extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M16.5,4.5V5c0,0,2.2,0,4.8,2.5c-1.9-1.3-4.8-2.4-8.1-2.4S7,6.1,4.9,7.5C7.7,5,9.7,5,9.7,5V4.5c-3.8,0.2-5.5,2-5.5,2	c-2.9,3.7-3.1,11.9-3.1,11.9c2.8,3.5,6.4,3,6.4,3l1.2-1.7c-1.4-0.5-2.8-1.5-3.5-2.4c2.1,1.5,4.8,2.4,7.9,2.4s5.9-1,7.9-2.2	c-0.7,0.7-1.7,1.7-3.6,2.2l1.2,1.7c0,0,3.6,0.5,6.4-3c0,0,0-8.2-3.1-11.9C22.2,6.5,20.3,4.7,16.5,4.5z M9.4,16.4	c-1.2,0-2.1-1-2.1-2.2S8.2,12,9.4,12s2.1,1,2.1,2.2S10.4,16.4,9.4,16.4z M16.8,16.4c-1.2,0-2.1-1-2.1-2.2s0.9-2.2,2.1-2.2	s2.1,1,2.1,2.2S17.8,16.4,16.8,16.4z" />
      </svg>
    )
  }
}
