import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import illlustration from "../../assets/images/intro.svg"
import Typist from "react-typist"
import "react-typist/dist/Typist.css"

export default () => (
  <Fragment>
    <video
  autoPlay={true}
      muted={true}
      loop={true}
      className="background-video"
    >
      <source src="./assets/videos/StockMarketAnimation.mp4" type="video/mp4" />
    </video>
    <div className="content">
      <div className="walkthrough-intro-wrap">
        <div className="walkthrough-intro-header">
          <img src={illlustration} alt="Intro to Lima" />
        </div>
        <div className="walkthrough-intro-content">
          <h1>
            {" "}
            <Typist>Explore Lima Capital's data science driven funds</Typist>
          </h1>
          <p>
            Our fund explorer helps you understand the possible performance of
            the Lima Capital funds compared to various benchmarks over various
            time periods.
          </p>
          <p>
            The Lima Capital funds consist of an underlying equity index with
            exposure to the index being managed purely through our proprietary
            data science framework.
          </p>

          <div className="intro-buttons-wrap">
            <Link
              to={{ pathname: "/" }}
              className="button primary-button explore-button"
            >
              Explore
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)
