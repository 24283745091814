import * as React from "react"
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  TooltipProps,
  Label,
} from "recharts"
import formatToDisplay, {
  format as formatToDisplayWithoutCurrency,
} from "../../lib/numbers/formatToDisplay"
import { i18n } from "../../i18n"

const renderTooltip = (tooltipProps: TooltipProps) => {
  if (!tooltipProps.payload) {
    return
  }

  const year = tooltipProps.label
  const predictedReturns = tooltipProps.payload
    .sort((a, b) => {
      const x = a.value
      const y = b.value
      return x > y ? -1 : x < y ? 1 : 0
    })
    .map(payload => ({
      name: payload.name,
      value: formatToDisplay(payload.value as number),
    }))

  return (
    <div className="graph-tooltip">
      <h6>{year}</h6>
      {predictedReturns.map(item => (
        <p key={item.name}>
          <span>
            {i18n.t(
              `tool.keys.${
                item.name === "S&P_500_Index_USD"
                  ? "SP_500_Index_USD"
                  : item.name
              }`,
            )}
            :
          </span>{" "}
          {item.value}
        </p>
      ))}
    </div>
  )
}

export default (props: any) => (
  <div className="graph">
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={props.data}>
        <CartesianGrid vertical={false} />
        <XAxis dataKey="month" minTickGap={40} tickMargin={13} height={53}>
          <Label
            value={i18n.t("tool.results.x_axis_label")}
            position="insideBottom"
          />
        </XAxis>
        <YAxis // TODO - google start y axis @ 80 000
          domain={["dataMin - 1000", "dataMax + 1000"]}
          tickFormatter={formatToDisplayWithoutCurrency}
          tickMargin={13}
          width={73}
        >
          <Label
            value={i18n.t("tool.results.y_axis_label")}
            angle={270}
            position="insideLeft"
            dy={53}
          />
        </YAxis>
        <Tooltip content={renderTooltip} />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={
            props.vanguardFundAggSelected && "Vanguard_LifeStrategy_Growth"
          }
          className="line line-vanguard_aggressive"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.eaaFundAggSelected && "MSCI_Pacific_ex_Japan"}
          className="line line-eaa_aggressive"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={
            props.vanguardFundModSelected && "Vanguard_LifeStrategy_Moderate"
          }
          className="line line-vanguard_moderate"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.eaaFundModSelected && "MSCI_Emerging_Markets"}
          className="line line-eaa_moderate"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={
            props.vanguardFundConsSelected &&
            "Vanguard_LifeStrategy_Conservative"
          }
          className="line line-vanguard_conservative"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.eaaFundConsSelected && "Bitcoin"}
          className="line line-eaa_conservative"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.vanguardSelected && "Vanguard_VTI"}
          className="line line-vanguard_fund"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.msciSelected && "MSCI_World_TR_Index"}
          className="line line-msci_fund"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.sp500Selected && "S&P_500_Index_USD"}
          className="line line-sp500_fund"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.ftseSelected && "FTSE_All_Share_GBP"}
          className="line line-ftse_fund"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.limaFundConSelected && "Voyager"}
          className="line line-Voyager"
        />

        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.limaFundModSelected && "Etherbridge"}
          className="line line-lima_moderate"
        />
        <Line
          animationDuration={500}
          type="monotone"
          dot={false}
          dataKey={props.limaFundAggSelected && "Lima_Unconstrained"}
          className="line line-lima_aggressive"
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
)
