import { RouterState } from "connected-react-router"
import ApplicationForm from "./applicationForm"

export enum FundTypes {
  CapitalConservative = "CapitalConservative",
  CapitalBalanced = "CapitalBalanced",
  CapitalUnconstrained = "CapitalUnconstrained",
  DigitalConservative = "DigitalConservative",
  DigitalBalanced = "DigitalBalanced",
  DigitalUnconstrained = "DigitalUnconstrained",
}

export enum IndexFundTypes {
  VTIAggressive = "VTIAggressive",
  MorningstarAggressive = "MorningstarAggressive",
  EAAAggressive = "EAAAgressive",
  VTIModerate = "VTIModerate",
  MorningstarModerate = "MorningstarModerate",
  EAAModerate = "EAAModerate",
  VTIConservative = "VTIConservative",
  MorningstarConservative = "MorningstarConservative",
  EAAConservative = "EAAConservative",
}

export interface AbstractState {
  [key: string]: any
}

interface ToolStateI {
  duration: number
  lumpSum: number
  monthlyContribution: number
  capitalFund: FundTypes | null
  digitalFund: FundTypes | null
  allocation: number
  graphFilter: GraphFilters
  graphData: GraphData
  highCaseOutcome: number
  lowCaseOutcome: number
  mediumCaseOutcome: number
}

interface WalkthroughStateI {
  isComplete: boolean
}

interface AppStateI {
  tool: ToolState
  walkthrough: WalkthroughState
  applicationForm: ApplicationForm
  router: RouterState
}

interface FundI {
  name: FundTypes
  allocation: number
}

export enum GraphFilters {
  Blend = "Blend",
  Digital = "Digital",
  Capital = "Capital",
}

type InvestmentI = Fund[]

interface InterestMapI {
  [key: string]: InterestRates
}

interface InterestRatesI {
  high: number
  low: number
  medium: number
}

interface PeriodI extends InterestRates {
  xaxis: string
}

type GraphDataI = Period[]

export type AppState = AppStateI
export type ToolState = ToolStateI
export type WalkthroughState = WalkthroughStateI
export type GraphData = GraphDataI
export type Period = PeriodI
export type InterestRates = InterestRatesI
export type InterestMap = InterestMapI
export type Fund = FundI
export type Investment = InvestmentI
