import * as types from "../constants/actionTypes"
import { GraphFilters, FundTypes } from "../models"

const updateFundFilter = (filter: GraphFilters) => ({
  filter,
  type: types.UPDATE_FUND_FILTER,
})

const setDigitalFund = (fund: FundTypes) => ({
  fund,
  type: types.SET_DIGITAL_FUND,
})

const setCapitalFund = (fund: FundTypes) => ({
  fund,
  type: types.SET_CAPITAL_FUND,
})

const allocateFundSplit = (capitalPercentage: number) => ({
  capitalPercentage,
  type: types.ALLOCATE_FUND_SPLIT,
})

const setLumpSum = (amount: number) => ({
  amount,
  type: types.SET_LUMPSUM,
})

const setMonthlyContribution = (amount: number) => ({
  amount,
  type: types.SET_MONTHLY_CONTRIBUTION,
})

const setDuration = (years: number) => ({
  years,
  type: types.UPDATE_DURATION,
})

const toggleWalkthroughComplete = (isComplete: boolean) => ({
  isComplete,
  type: types.TOGGLE_WALKTHROUGH_COMPLETE,
})

export {
  updateFundFilter,
  setDigitalFund,
  setCapitalFund,
  allocateFundSplit,
  setLumpSum,
  setMonthlyContribution,
  setDuration,
  toggleWalkthroughComplete,
}
