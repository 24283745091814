import * as React from "react"

export default class Telegram extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M9.7,22.7l0.4-6.2l11-10.2C21.7,5.9,21,5.7,20.4,6L6.8,14.8L0.9,13c-1.3-0.3-1.3-1.2,0.3-1.9l23-8.9	c1.1-0.4,2.1,0.2,1.6,1.9L22,22.7c-0.3,1.3-1.1,1.7-2.2,1l-5.9-4.4l-2.8,2.9C10.6,22.3,10.3,22.7,9.7,22.7z" />
      </svg>
    )
  }
}
