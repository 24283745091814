import * as React from "react"
import {
  CapitalConservativeIcon,
  CapitalBalancedIcon,
  CapitalUnconstrainedIcon,
  LimaIcon,
} from "../../../assets/icons"
import { FundTypes } from "../../../models"
import { i18n } from "../../../i18n"
import Tooltip from "../../Tooltip/Tooltip"

interface VTIIndexChoiceProps {
  limaFundConSelected: any
  setLimaFundConSelected: any
  setLimaFundModSelected: any
  limaFundModSelected: any
  setLimaFundAggSelected: any
  limaFundAggSelected: any
  showTooltips?: boolean // TODO Tooltips
}

export default class VTIIndexChoice extends React.Component<
  VTIIndexChoiceProps
> {
  constructor(props: VTIIndexChoiceProps) {
    super(props)
  }

  public render() {
    return (
      <div className="inv-choices-buttons-wrap">
        <div className="capital-buttons-wrap">
          <div
            onClick={this.handleVTIModSelect}
            className={`${FundTypes.CapitalBalanced} inv-choices-button ${this
              .props.limaFundModSelected && "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionY="bottom"
                open={
                  this.props.limaFundModSelected === FundTypes.CapitalBalanced
                }
              >
                <p>
                  This is for you if you're looking for long-term capital
                  growth.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <LimaIcon />
            </div>
            <div className="inv-choices-button-info">
              <CapitalConservativeIcon />
              <h6>{i18n.t("tool.inv_choices.etherbridge")}</h6>
              <p>{i18n.t("tool.inv_choices.etherbridge")}</p>
            </div>
          </div>
          <div
            onClick={this.handleVTIConsSelect}
            className={`${
              FundTypes.CapitalConservative
            } inv-choices-button ${this.props.limaFundConSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="bottom"
                open={
                  this.props.limaFundConSelected ===
                  FundTypes.CapitalConservative
                }
              >
                <p>
                  This is for you if you're looking for stable growth at
                  moderate levels of volatility.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <LimaIcon />
            </div>
            <div className="inv-choices-button-info">
              <CapitalBalancedIcon />
              <h6>{i18n.t("tool.inv_choices.bakari")}</h6>
              <p>{i18n.t("tool.inv_choices.defi")}</p>
            </div>
          </div>

          <div
            onClick={this.handleVTIAggSelect}
            className={`${
              FundTypes.CapitalUnconstrained
            } inv-choices-button ${this.props.limaFundAggSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="right"
                positionY="bottom"
                open={
                  this.props.limaFundAggSelected ===
                  FundTypes.CapitalUnconstrained
                }
              >
                <p>
                  This is for you if you're willing to put up with short-term
                  market volatility for the potential of greater long-term
                  returns.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <LimaIcon />
            </div>
            <div className="inv-choices-button-info">
              <CapitalUnconstrainedIcon />
              <h6>{i18n.t("tool.inv_choices.lima")}</h6>
              <p>{i18n.t("tool.inv_choices.unconstrained")}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleVTIConsSelect = () => {
    this.props.limaFundConSelected
      ? this.props.setLimaFundConSelected(false)
      : this.props.setLimaFundConSelected(true)
  }

  private handleVTIModSelect = () => {
    this.props.limaFundModSelected
      ? this.props.setLimaFundModSelected(false)
      : this.props.setLimaFundModSelected(true)
  }

  private handleVTIAggSelect = () => {
    this.props.limaFundAggSelected
      ? this.props.setLimaFundAggSelected(false)
      : this.props.setLimaFundAggSelected(true)
  }
}
