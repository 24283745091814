import * as React from "react"
import {
  DigitalConservativeIcon,
  DigitalBalancedIcon,
  DigitalUnconstrainedIcon,
  VanguardIcon,
  MsciIcon,
  BitcoinIcon,
} from "../../../assets/icons"
import { FundTypes } from "../../../models"
import { i18n } from "../../../i18n"
import Tooltip from "../../Tooltip/Tooltip"

// TODO decide on tooltips

interface PeerGroupIndexesProps {
  eaaFundConsSelected: any
  eaaFundModSelected: any
  eaaFundAggSelected: any
  seteaaFundConsSelected: any
  seteaaFundModSelected: any
  seteaaFundAggSelected: any
  vanguardFundConsSelected: any
  vanguardFundModSelected: any
  vanguardFundAggSelected: any
  setVanguardFundConsSelected: any
  setVanguardFundModSelected: any
  setVanguardFundAggSelected: any

  showTooltips?: boolean
}

export default class PeerGroupIndexes extends React.Component<
  PeerGroupIndexesProps
> {
  constructor(props: PeerGroupIndexesProps) {
    super(props)
  }

  public render() {
    return (
      <div className="inv-choices-buttons-wrap">
        <div className="peergroups-buttons-wrap">
          <div
            onClick={this.handleVanguardConsSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.vanguardFundConsSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.vanguardFundConsSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <VanguardIcon />
            </div>
            <div className="inv-choices-button-info vanguard-info">
              <DigitalConservativeIcon />
              <h6>
                {i18n.t("tool.benchmark_indexes.vanguard_lifestrategy_fund")}
              </h6>
              <p>{i18n.t("tool.benchmark_indexes.conservative")}</p>
            </div>
          </div>
          <div
            onClick={this.handleVanguardModSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.vanguardFundModSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.vanguardFundModSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <VanguardIcon />
            </div>
            <div className="inv-choices-button-info  vanguard-info">
              <DigitalBalancedIcon />
              <h6>
                {i18n.t("tool.benchmark_indexes.vanguard_lifestrategy_fund")}
              </h6>
              <p>{i18n.t("tool.inv_choices.balanced")}</p>
            </div>
          </div>
          <div
            onClick={this.handleVanguardAggSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.vanguardFundAggSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.vanguardFundAggSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <VanguardIcon />
            </div>
            <div className="inv-choices-button-info vanguard-info">
              <DigitalUnconstrainedIcon />
              <h6>
                {i18n.t("tool.benchmark_indexes.vanguard_lifestrategy_fund")}
              </h6>
              <p>{i18n.t("tool.benchmark_indexes.growth")}</p>
            </div>
          </div>
        </div>
        <div className="digital-buttons-wrap">
          <div
            onClick={this.handleEAAConsSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.eaaFundConsSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.eaaFundConsSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <BitcoinIcon />
            </div>
            <div className="inv-choices-button-info morningstar-info">
              <DigitalConservativeIcon />
              <h6>{i18n.t("tool.benchmark_indexes.bitcoin")}</h6>
              <p>{i18n.t("tool.benchmark_indexes.cautious")}</p>
            </div>
          </div>
          <div
            onClick={this.handleEAAModSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.eaaFundModSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.eaaFundModSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <MsciIcon />
            </div>
            <div className="inv-choices-button-info morningstar-info">
              <DigitalBalancedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.eaa_fund")}</h6>
              <p>{i18n.t("tool.benchmark_indexes.moderate")}</p>
            </div>
          </div>
          <div
            onClick={this.handleEAAAggSelect}
            className={`${
              FundTypes.DigitalConservative
            } inv-choices-button ${this.props.eaaFundAggSelected &&
              "selected"}`}
          >
            {this.props.showTooltips && (
              <Tooltip
                positionX="left"
                positionY="top"
                open={
                  this.props.eaaFundAggSelected ===
                  FundTypes.DigitalConservative
                }
              >
                <p>
                  <b>
                    This is for you if you're primarily looking to protect
                    against capital loss.
                  </b>
                </p>
                <br />
                <p>
                  Up to 100% of the investment can be protected as cash
                  equivalent.
                </p>
              </Tooltip>
            )}
            <div className="bg-icon">
              <MsciIcon />
            </div>
            <div className="inv-choices-button-info morningstar-info">
              <DigitalUnconstrainedIcon />
              <h6>{i18n.t("tool.benchmark_indexes.eaa_fund")}</h6>
              <p>{i18n.t("tool.benchmark_indexes.aggressive")}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleVanguardConsSelect = () => {
    this.props.vanguardFundConsSelected
      ? this.props.setVanguardFundConsSelected(false)
      : this.props.setVanguardFundConsSelected(true)
  }

  private handleVanguardModSelect = () => {
    this.props.vanguardFundModSelected
      ? this.props.setVanguardFundModSelected(false)
      : this.props.setVanguardFundModSelected(true)
  }

  private handleVanguardAggSelect = () => {
    this.props.vanguardFundAggSelected
      ? this.props.setVanguardFundAggSelected(false)
      : this.props.setVanguardFundAggSelected(true)
  }

  private handleEAAConsSelect = () => {
    this.props.eaaFundConsSelected
      ? this.props.seteaaFundConsSelected(false)
      : this.props.seteaaFundConsSelected(true)
  }

  private handleEAAModSelect = () => {
    this.props.eaaFundModSelected
      ? this.props.seteaaFundModSelected(false)
      : this.props.seteaaFundModSelected(true)
  }

  private handleEAAAggSelect = () => {
    this.props.eaaFundAggSelected
      ? this.props.seteaaFundAggSelected(false)
      : this.props.seteaaFundAggSelected(true)
  }
}
