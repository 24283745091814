import * as React from "react"
import format from "../../lib/numbers/formatToDisplay"
import { i18n } from "../../i18n"

export default (props: any) => (
  <div className="results-wrap">
    <div className="results">
      {props.limaFundConSelected && (
        <div key={"Voyager"} className="keys">
          <h6 className={`result result-Voyager solid-line`}>
            {format(props.graphKeys["Voyager"])}
          </h6>
          <h6 className={`label result result-Voyager solid-line`}>
            {i18n.t(`tool.keys.Voyager`)}
          </h6>
        </div>
      )}
      {props.limaFundModSelected && (
        <div key={"Etherbridge"} className="keys">
          <h6 className={`result result-lima_moderate solid-line`}>
            {format(props.graphKeys["Etherbridge"])}
          </h6>
          <h6 className={`label result result-lima_moderate solid-line`}>
            {i18n.t(`tool.keys.Etherbridge`)}
          </h6>
        </div>
      )}
      {props.limaFundAggSelected && (
        <div key={"Lima_Unconstrained"} className="keys">
          <h6 className={`result result-lima_aggressive solid-line`}>
            {format(props.graphKeys["Lima_Unconstrained"])}
          </h6>
          <h6 className={`label result result-lima_aggressive solid-line`}>
            {i18n.t(`tool.keys.Lima_Unconstrained`)}
          </h6>
        </div>
      )}
      {props.vanguardFundConsSelected && (
        <div key={"Vanguard_LifeStrategy_Conservative"} className="keys">
          <h6 className={`result result-vanguard_conservative solid-line`}>
            {format(props.graphKeys["Vanguard_LifeStrategy_Conservative"])}
          </h6>
          <h6
            className={`label result result-vanguard_conservative solid-line`}
          >
            {i18n.t(`tool.keys.${"Vanguard_LifeStrategy_Conservative"}`)}
          </h6>
        </div>
      )}
      {props.vanguardFundModSelected && (
        <div key={"Vanguard_LifeStrategy_Moderate"} className="keys">
          <h6 className={`result result-vanguard_moderate solid-line`}>
            {format(props.graphKeys["Vanguard_LifeStrategy_Moderate"])}
          </h6>
          <h6 className={`label result result-vanguard_moderate solid-line`}>
            {i18n.t(`tool.keys.${"Vanguard_LifeStrategy_Moderate"}`)}
          </h6>
        </div>
      )}
      {props.vanguardFundAggSelected && (
        <div key={"Vanguard_LifeStrategy_Growth"} className="keys">
          <h6 className={`result result-vanguard_aggressive solid-line`}>
            {format(props.graphKeys["Vanguard_LifeStrategy_Growth"])}
          </h6>
          <h6 className={`label result result-vanguard_aggressive solid-line`}>
            {i18n.t(`tool.keys.${"Vanguard_LifeStrategy_Growth"}`)}
          </h6>
        </div>
      )}
      {props.eaaFundConsSelected && (
        <div key={"Bitcoin"} className="keys">
          <h6 className={`result result-eaa_conservative solid-line`}>
            {format(props.graphKeys["Bitcoin"])}
          </h6>
          <h6 className={`label result result-eaa_conservative solid-line`}>
            {i18n.t(`tool.keys.${"Bitcoin"}`)}
          </h6>
        </div>
      )}
      {props.eaaFundModSelected && (
        <div key={"MSCI_Emerging_Markets"} className="keys">
          <h6 className={`result result-eaa_moderate solid-line`}>
            {format(props.graphKeys["MSCI_Emerging_Markets"])}
          </h6>
          <h6 className={`label result result-eaa_moderate solid-line`}>
            {i18n.t(`tool.keys.${"MSCI_Emerging_Markets"}`)}
          </h6>
        </div>
      )}
      {props.eaaFundAggSelected && (
        <div key={"MSCI_Pacific_ex_Japan"} className="keys">
          <h6 className={`result result-eaa_aggressive solid-line`}>
            {format(props.graphKeys["MSCI_Pacific_ex_Japan"])}
          </h6>
          <h6 className={`label result result-eaa_aggressive solid-line`}>
            {i18n.t(`tool.keys.${"MSCI_Pacific_ex_Japan"}`)}
          </h6>
        </div>
      )}
      {props.vanguardSelected && (
        <div key={"Vanguard_VTI"} className="keys">
          <h6 className={`result result-vanguard_index`}>
            {format(props.graphKeys["Vanguard_VTI"])}
          </h6>
          <h6 className={`label result result-vanguard_index`}>
            {i18n.t(`tool.keys.${"Vanguard_VTI"}`)}
          </h6>
        </div>
      )}
      {props.msciSelected && (
        <div key={"MSCI_World_TR_Index"} className="keys">
          <h6 className={`result result-msci_index`}>
            {format(props.graphKeys["MSCI_World_TR_Index"])}
          </h6>
          <h6 className={`label result result-msci_index`}>
            {i18n.t(`tool.keys.MSCI_World_TR_Index`)}
          </h6>
        </div>
      )}
      {props.sp500Selected && (
        <div key={"S&P_500_Index_USD"} className="keys">
          <h6 className={`result result-sp500_index`}>
            {format(props.graphKeys["S&P_500_Index_USD"])}
          </h6>
          <h6 className={`label result result-sp500_index`}>
            {i18n.t(`tool.keys.SP_500_Index_USD`)}
          </h6>
        </div>
      )}
      {props.ftseSelected && (
        <div key={"FTSE_All_Share_GBP"} className="keys">
          <h6 className={`result result-ftse_index`}>
            {format(props.graphKeys["FTSE_All_Share_GBP"])}
          </h6>
          <h6 className={`label result result-ftse_index`}>
            {i18n.t(`tool.keys.FTSE_All_Share_GBP`)}
          </h6>
        </div>
      )}
    </div>
  </div>
)
