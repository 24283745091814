import toTwoDecimalPlaces from "../numbers/decimalRounding"

const monthlyContributionFutureValue = (
  monthlyContribution: number,
  interestRate: number,
  years: number,
): number => {
  const monthlyInterestRate = interestRate / 12
  const numberOfPeriods = years * 12
  const calculation =
    monthlyContribution *
    ((Math.pow(monthlyInterestRate + 1, numberOfPeriods) - 1) /
      monthlyInterestRate)
  return toTwoDecimalPlaces(calculation)
}

const lumpSumFutureValue = (
  lumpSum: number,
  interestRate: number,
  years: number,
): number => {
  const monthlyInterestRate = interestRate / 12
  const numberOfPeriods = years * 12
  const calculation =
    lumpSum * Math.pow(1 + monthlyInterestRate, numberOfPeriods)
  return toTwoDecimalPlaces(calculation)
}

const futureValue = (
  interestRate: number,
  years: number,
  lumpSum: number,
  monthlyContribution: number,
): number =>
  monthlyContributionFutureValue(monthlyContribution, interestRate, years) +
  lumpSumFutureValue(lumpSum, interestRate, years)

export default futureValue
export { monthlyContributionFutureValue, lumpSumFutureValue }
