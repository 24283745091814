import * as React from "react"
import VTIIndexChoice from "./InvChoices/VTIIndexChoice"
import TimeFrame from "./Timeframes/Timeframes"
import PeerGroupIndexes from "./PeerGroupIndexes/PeerGroupIndexes"
import BenchMarkIndexes from "./BenchMarkIndexes/BenchMarkIndexes"
import Tabs from "../Tabs"
import { i18n } from "../../i18n"

interface ControlsProps {
  limaFundConSelected: any
  setLimaFundConSelected: any
  setLimaFundModSelected: any
  limaFundModSelected: any
  setLimaFundAggSelected: any
  limaFundAggSelected: any

  eaaFundConsSelected: any
  eaaFundModSelected: any
  eaaFundAggSelected: any
  seteaaFundConsSelected: any
  seteaaFundModSelected: any
  seteaaFundAggSelected: any

  vanguardFundConsSelected: any
  vanguardFundModSelected: any
  vanguardFundAggSelected: any
  setVanguardFundConsSelected: any
  setVanguardFundModSelected: any
  setVanguardFundAggSelected: any

  vanguardSelected: any
  setVanguardSelected: any
  msciSelected: any
  setMsciSelected: any
  sp500Selected: any
  setSp500Selected: any
  ftseSelected: any
  setFtseSelected: any

  cumulateData: any
  graphData: any

  duration: number
  setDuration: (x: number) => object
}

export default (props: ControlsProps) => (
  <Tabs>
    <div data-label={i18n.t("tool.inv_choices.tab_heading")}>
      <div className="inv-choices-wrap">
        <h6 className="desktop-title">{i18n.t("tool.index.heading")}</h6>
        <VTIIndexChoice
          limaFundConSelected={props.limaFundConSelected}
          setLimaFundConSelected={props.setLimaFundConSelected}
          setLimaFundModSelected={props.setLimaFundModSelected}
          limaFundModSelected={props.limaFundModSelected}
          setLimaFundAggSelected={props.setLimaFundAggSelected}
          limaFundAggSelected={props.limaFundAggSelected}
        />
      </div>
      {/* </div>
    <div data-label={i18n.t("tool.inv_amount.tab_heading")}> */}
      <div className="inv-choices-wrap">
        <h6 className="desktop-title">
          {i18n.t("tool.peergroup_indexes.heading")}
        </h6>
        <PeerGroupIndexes
          eaaFundConsSelected={props.eaaFundConsSelected}
          eaaFundModSelected={props.eaaFundModSelected}
          eaaFundAggSelected={props.eaaFundAggSelected}
          seteaaFundConsSelected={props.seteaaFundConsSelected}
          seteaaFundModSelected={props.seteaaFundModSelected}
          seteaaFundAggSelected={props.seteaaFundAggSelected}
          vanguardFundConsSelected={props.vanguardFundConsSelected}
          vanguardFundModSelected={props.vanguardFundModSelected}
          vanguardFundAggSelected={props.vanguardFundAggSelected}
          setVanguardFundConsSelected={props.setVanguardFundConsSelected}
          setVanguardFundModSelected={props.setVanguardFundModSelected}
          setVanguardFundAggSelected={props.setVanguardFundAggSelected}
        />
      </div>
      <div className="inv-choices-wrap">
        <h6 className="desktop-title">
          {i18n.t("tool.benchmark_indexes.heading")}
        </h6>
        <BenchMarkIndexes
          vanguardSelected={props.vanguardSelected}
          setVanguardSelected={props.setVanguardSelected}
          msciSelected={props.msciSelected}
          setMsciSelected={props.setMsciSelected}
          sp500Selected={props.sp500Selected}
          setSp500Selected={props.setSp500Selected}
          ftseSelected={props.ftseSelected}
          setFtseSelected={props.setFtseSelected}
        />
      </div>
      {/* </div>
    <div data-label={i18n.t("tool.inv_timeframe.tab_heading")}> */}
      <div className="inv-choices-wrap">
        <h6 className="desktop-title">
          {i18n.t("tool.inv_timeframe.heading")}
        </h6>
        <TimeFrame
          cumulateData={props.cumulateData}
          duration={props.duration}
        />
      </div>
    </div>
    <div />
  </Tabs>
)
