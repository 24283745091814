import * as React from "react"

export default class Facebook extends React.Component {
  public render() {
    return (
      <svg viewBox="0 0 26 26">
        <path d="M19.5,0L19.5,0L19.5,0v5.2h-2.6c-0.9,0-1.3,1.1-1.3,2v3.2l0,0h3.9v5.2h-3.9V26h-5.2V15.6H6.5v-5.2h3.9V5.2	c0-2.9,2.3-5.2,5.2-5.2H19.5z" />
      </svg>
    )
  }
}
