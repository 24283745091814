import * as React from "react"
import { i18n } from "../../../i18n"
import Tooltip from "../../Tooltip/Tooltip"

interface TimeFrameProps {
  duration: number
  cumulateData: (x: number) => void
}

class TimeFrame extends React.Component<
  TimeFrameProps,
  { isHovering: boolean }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      isHovering: false,
    }
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState)
  }

  toggleHoverState = () => {
    return {
      isHovering: !this.state.isHovering,
    }
  }

  public render() {
    const { duration, cumulateData } = this.props

    return (
      <div className="timeframe-wrap">
        <div className="timeframe-buttons-wrap">
          <div
            onClick={() => {
              cumulateData(1)
            }}
            className={`timeframe-button  ${duration === 1 && "selected"}`}
          >
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.one_year")}</h6>
            </div>
          </div>

          <div
            onClick={() => {
              cumulateData(2)
            }}
            className={`timeframe-button  ${duration === 2 && "selected"}`}
          >
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.two_years")}</h6>
            </div>
          </div>

          <div
            onClick={() => {
              cumulateData(3)
            }}
            className={`timeframe-button  ${duration === 3 && "selected"}`}
          >
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.three_years")}</h6>
            </div>
          </div>

          <div
            onClick={() => {
              cumulateData(5)
            }}
            className={`timeframe-button  ${duration === 5 && "selected"}`}
          >
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.five_years")}</h6>
            </div>
          </div>

          <div
            onClick={() => {
              cumulateData(10)
            }}
            className={`timeframe-button  ${duration === 10 && "selected"}`}
          >
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.ten_years")}</h6>
            </div>
          </div>

          <div
            onMouseEnter={this.handleMouseHover}
            onMouseLeave={this.handleMouseHover}
            onClick={() => {
              cumulateData(100)
            }}
            className={`timeframe-button  ${duration === 100 && "selected"}`}
          >
            <Tooltip
              positionX="right"
              positionY="top"
              open={this.state.isHovering}
            >
              <p>From January 2009</p>
            </Tooltip>
            <div className="timeframe-button-info">
              <h6>{i18n.t("tool.timeframe.max_period")}</h6>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TimeFrame
