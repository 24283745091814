export const API_BASE_URL = process.env.REACT_APP_API_HOST
export const API_KEY = process.env.REACT_APP_API_KEY
export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENTID,
  callbackUrl: process.env.REACT_APP_AUTH_CALLBACK_URL,
  logoutReturnUrl: process.env.REACT_APP_AUTH_LOGOUT_RETURN_URL,
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
}
export const KANE_LOGIN_URL = process.env.REACT_APP_KANE_LOGIN_URL
