import * as React from "react"
// import Tab from "./Tab"

interface TabsProps {
  children: JSX.Element[]
}

class Tabs extends React.Component<TabsProps, { activeTab: string }> {
  constructor(props: TabsProps) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.label,
    }
  }

  public componentDidMount() {
    this.updateTabDisplay()
  }

  public render() {
    return (
      <div className="tabs">
        {/* <div className="tab-buttons-wrap controls-tab-buttons-wrap">
          {React.Children.map(
            this.props.children,
            (child: React.ReactElement<{ [label: string]: string }>) => {
              const label = child.props.label

              return (
                <Tab
                  activeTab={this.state.activeTab}
                  key={label}
                  label={label}
                  onClick={this.onClickTabItem}
                />
              )
            },
          )}
        </div> */}
        <div className="tab-content">
          {React.Children.map(
            this.props.children,
            (child: React.ReactElement<{ [label: string]: string }>) => {
              return (
                <div className={`tab-content-inner ${child.props.label}-tab`}>
                  {child.props.children}
                </div>
              )
            },
          )}
        </div>
      </div>
    )
  }

  private updateTabDisplay() {
    document
      .querySelectorAll(".tab-content-inner")
      .forEach(item => item.classList.add("hidden-tab"))
    const activeTab = document.querySelector(`.${this.state.activeTab}-tab`)
    if (activeTab) {
      activeTab.classList.remove("hidden-tab")
    }
  }

  // private onClickTabItem = (tab: string) => {
  //   this.setState({ activeTab: tab }, () => this.updateTabDisplay())
  // }
}

export default Tabs
