import * as React from "react"
import { i18n } from "../../i18n"

export default () => (
  <div className="secondary-footer">
    <a
      href="https://assets.gostackr.com/Lima-Capital-ML-Framework.pdf"
      target="_blank"
      className="link-button"
    >
      {i18n.t("disclaimer")}
    </a>
  </div>
)
