import commariser from "./commariser"

const billionFormatter = (x: string): string => {
  const numberOfBillions = commariser(x.slice(0, x.length - 9))
  const decimals = x.slice(x.length - 9, x.length - 7)
  const nonZero = decimals !== "00"

  return nonZero
    ? `${numberOfBillions}.${zeroTrimmer(decimals)}B`
    : `${numberOfBillions}B`
}

const zeroTrimmer = (x: string) =>
  x[x.length - 1] === "0" ? x.slice(0, x.length - 1) : x

const millionFormatter = (x: string): string => {
  const numberOfMillions = commariser(x.slice(0, x.length - 6))
  const decimals = x.slice(x.length - 6, x.length - 4)
  const nonZero = decimals !== "00"

  return nonZero
    ? `${numberOfMillions}.${zeroTrimmer(decimals)}m`
    : `${numberOfMillions}m`
}
const thousandFormatter = (x: string): string => {
  const numberOfThousands = x.slice(0, x.length - 3)
  const decimals = x.slice(x.length - 3, x.length - 1)
  const nonZero = decimals !== "00"

  return nonZero
    ? `${numberOfThousands}.${zeroTrimmer(decimals)}k`
    : `${numberOfThousands}k`
}

export const format = (x: number): string => {
  const integralNumber = Math.floor(x)

  if (integralNumber > 999999999) {
    return `${billionFormatter(`${integralNumber}`)}`
  }

  if (integralNumber > 999999) {
    return `${millionFormatter(`${integralNumber}`)}`
  }

  if (integralNumber > 999) {
    return `${thousandFormatter(`${integralNumber}`)}`
  }

  return `${integralNumber}`
}

const formatWithCurrencySymbol = (x: number) => `US$ ${format(x)}`

export default formatWithCurrencySymbol
