import {
  AppState,
  GraphFilters,
  ToolState,
  WalkthroughState,
} from "../../models"
import { RouterState } from "connected-react-router"
import ApplicationFormState from "../../models/applicationForm"
import calculateGraph from "../graph"
import { Location } from "history"

const TOOL_STATE_KEY = "toolState"
const WALKTHROUGH_STATE_KEY = "walkthroughState"
const APPLICATIONFORM_STATE_KEY = "applicationFormState"

const initialToolState: ToolState = calculateGraph({
  duration: 10,
  lumpSum: 100000,
  monthlyContribution: 100,
  capitalFund: null,
  digitalFund: null,
  allocation: 50,
  graphFilter: GraphFilters.Blend,
  graphData: [],
  highCaseOutcome: 0,
  lowCaseOutcome: 0,
  mediumCaseOutcome: 0,
})

const initialWalkthroughState: WalkthroughState = {
  isComplete: false,
}

const applicationFormState: ApplicationFormState = {
  email: "",
  surname: "",
  name: "",
  dateOfBirth: "",
  identificationType: "",
  identificationNumber: "",
  identificationExpiration: "",
  identificationCountry: "",
  birthPlace: "",
  birthCountry: "",
  countriesOfCitizenship: [],
  streetAddress: "",
  zipCode: "",
  city: "",
  province: "",
  country: "",
  planNumber: "",
  submissionError: "",
  isSubmitted: false,
}

const initialRouterState: RouterState = {
  location: {
    pathname: "/",
    search: "",
    hash: "",
  } as Location<string>,
  action: "POP",
}

const initialAppState: AppState = {
  tool: initialToolState,
  walkthrough: initialWalkthroughState,
  applicationForm: applicationFormState,
  router: initialRouterState,
}

const saveAppState = (state: AppState) => {
  // Because the entire state must not be persisted - only persist the required sub state
  saveToLocalStorage(TOOL_STATE_KEY, JSON.stringify(state.tool))
  saveToLocalStorage(WALKTHROUGH_STATE_KEY, JSON.stringify(state.walkthrough))
  saveToLocalStorage(
    APPLICATIONFORM_STATE_KEY,
    JSON.stringify(state.applicationForm),
  )
}

const loadAppState = (): AppState => {
  const serializedToolState = retrieveFromLocalStorage(TOOL_STATE_KEY)
  const serializedWalkthroughState = retrieveFromLocalStorage(
    WALKTHROUGH_STATE_KEY,
  )
  const serializedApplicationFormState = retrieveFromLocalStorage(
    APPLICATIONFORM_STATE_KEY,
  )

  // Load the initial app state then see if the others are valid
  // And if so set the sub state
  const appState: AppState = initialAppState
  if (serializedToolState) {
    try {
      appState.tool = JSON.parse(serializedToolState)
    } catch {
      // Handle errors later if required
    }
  }
  if (serializedWalkthroughState) {
    try {
      appState.walkthrough = JSON.parse(serializedWalkthroughState)
    } catch {
      // Handle errors later if required
    }
  }
  if (serializedApplicationFormState) {
    try {
      appState.applicationForm = JSON.parse(serializedApplicationFormState)
    } catch {
      // Handle errors later if required
    }
  }
  return appState
}

const saveToLocalStorage = (key: string, stringifiedData: string) => {
  try {
    localStorage.setItem(key, stringifiedData)
  } catch (err) {
    // Handle errors later if required
  }
}

const retrieveFromLocalStorage = (key: string) => {
  try {
    const serializedData = localStorage.getItem(key)
    return serializedData
  } catch (err) {
    return null
  }
}

export { loadAppState, saveAppState }
